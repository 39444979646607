@charset "UTF-8";
/**
 * Set project font stacks.
 * Generic fallbacks are provided but should be tailored to the project.
 *
 * $sans-serif-fallback: Helvetica, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
 * $serif-fallback: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
 */
/**
 * Set third-party variable overrides.
 * e.g. Slick, Bourbon
 */
/**
 * 2-step colour system.
 * A colour is defined using it's name, e.g. red, violet, peach
 * Then the possible use cases are defined referring to the named colours,
 * e.g. $color-body, $color-header-bg.
 *
 * The use case variables are preferred for most styling cases
 * with the colour name variables being used as sparingly as possible
 */
*,
*:before,
*:after {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

body,
h1, h2, h3, h4,
p,
ul[class],
ol[class],
li,
figure, figcaption,
blockquote,
dl, dd {
  margin: 0;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

/**
 * 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 * 3. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  min-height: 100%;
  /* [2] */
  -webkit-text-size-adjust: 100%;
  /* [3] */
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a,
button {
  cursor: pointer;
}

/**
 * Turn off default button styles. 
 * Allows buttons to be used for pure semantics if needed.
 * Any designed buttons will use a component class.
 */
button {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  color: inherit;
  font-weight: inherit;
  text-transform: inherit;
  text-align: left;
}

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  max-width: 100%;
  /* [1] */
  font-style: italic;
  /* [2] */
  vertical-align: middle;
  /* [3] */
  object-fit: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

/**
 * Ensure hidden elements are hidden.
 * (Opinionated) !important used so that components can't accidentally make them visible.
 */
template,
[hidden] {
  display: none !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/**
 * Load webfonts.
 * If using @font-face set `font-display: swap;`
 */
:root {
  --container-padding: 2rem;
  --header-color: $color-text;
}
@media only screen and (max-width: 47.9375em) {
  :root {
    --container-padding: 1rem;
  }
}

html {
  position: relative;
  display: block;
  z-index: 0;
  overflow: auto;
}

body {
  color: #030303;
  font-family: nobel, Helvetica, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: clamp(1rem, calc(1rem + 0.625vw), 1.5rem);
  font-weight: 300;
}
body.page-contact {
  --header-color: white;
  background-color: #030303;
  color: white;
}

body.page-contact .page-contact__hide {
  display: none;
}

body:not(.page-contact) .page-contact__show {
  display: none;
}

/**
 * Heading styles.
 * Class version can be used to style a h1 as a h2 for semantic purposes.
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: nobel, Helvetica, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
}

h1,
.h1 {
  --min-font-size: 140px;
  font-size: clamp(var(--min-font-size), calc(1rem + 11.25vw), 232px);
  line-height: 1;
}
@media only screen and (max-width: 47.9375em) {
  h1,
  .h1 {
    --min-font-size: 50px;
    font-size: clamp(var(--min-font-size), calc(1rem + 13vw), 232px);
  }
}

h2,
.h2 {
  --min-font-size: 100px;
  font-size: clamp(var(--min-font-size), calc(1rem + 6.66vw), 144px);
  line-height: 1;
}
@media only screen and (max-width: 47.9375em) {
  h2,
  .h2 {
    --min-font-size: 60px;
  }
}

h3,
.h3 {
  font-size: 1.66em;
}

strong {
  font-weight: 700;
}

/**
 * Link element pseudo classes.
 */
a:not([class]) {
  color: inherit;
}

/**
 * Default table styling.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table caption {
  caption-side: bottom;
  padding-top: 0.5em;
  font-size: 0.9em;
}
table th {
  font-weight: 700;
}
table th,
table td {
  min-width: 250px;
  padding: 0.25em 0.5em;
  border: 1px solid white;
  color: white;
  text-align: right;
}
@media only screen and (max-width: 53.125em) {
  table th,
  table td {
    min-width: 30vw;
  }
}
table th:first-child,
table td:first-child {
  background-color: #030303;
  text-align: left;
}
@media only screen and (max-width: 25em) {
  table th:first-child,
  table td:first-child {
    min-width: 1px;
  }
}
table th:nth-child(2),
table td:nth-child(2) {
  background-color: #c77f00;
}
table th:nth-child(3),
table td:nth-child(3) {
  background-color: #DCBA78;
}

ul:not([class]) {
  margin: 0;
  padding-left: 0;
}
ul:not([class]) li {
  padding-left: 0.5em;
  border-left: 4px solid #c77f00;
  list-style-type: none;
  line-height: 1.2;
}
ul:not([class]) li + li {
  margin-top: 1em;
}
ul:not([class]) ul:not([class]) {
  margin-top: 1em;
  margin-left: 0.5em;
}
ul:not([class]) ul:not([class]) li {
  border-left-color: #DCBA78;
}

[id] {
  scroll-margin-top: calc(60px + 4ex);
}

.site-main {
  overflow: hidden;
}

.fluid {
  display: block;
  height: auto;
}

.width\:100 {
  width: 100%;
}

.flow .flow {
  --flow-space: 1em;
}
.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

.page-privacy-policy .flow.flow > * + * {
  margin-top: var(--flow-space, 1em);
}
.page-privacy-policy .flow.flow h3 {
  --flow-space: 1.5em;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10%;
}
@media only screen and (max-width: 75em) {
  .row {
    gap: 2em;
  }
}
@media only screen and (max-width: 59.375em) {
  .row {
    flex-direction: column;
    gap: 3em;
  }
}

.column {
  flex: 1 0 50%;
  max-width: calc(50% - 2em);
}
@media only screen and (max-width: 75em) {
  .column {
    max-width: 47.5%;
  }
}
@media only screen and (max-width: 59.375em) {
  .column {
    width: 100%;
    max-width: 100%;
  }
  .column img {
    width: 100%;
  }
}
.column.offset-v {
  margin-top: 3em;
}
@media only screen and (max-width: 75em) {
  .column.offset-v {
    margin-top: 1em;
  }
}
@media only screen and (min-width: 100.0625em) {
  .row.overflow-right .column {
    max-width: 50%;
  }
  .row.overflow-right .column:last-child img {
    width: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }
}

@media only screen and (min-width: 48em) {
  .list-columns-2 {
    columns: 2;
    column-gap: 3em;
  }
  .list-columns-2 ul {
    display: block;
    width: 100%;
  }
  .list-columns-2 li {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.qty {
  display: inline-block;
  min-width: 4ch;
}

.ch2-settings-options table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ch2-settings-options table caption {
  caption-side: bottom;
  padding-top: 0.5em;
  font-size: 0.9em;
}
.ch2-settings-options table th {
  font-weight: 700;
}
.ch2-settings-options table th,
.ch2-settings-options table td {
  min-width: 0px;
  padding: 0.25em 0.5em;
  border: 1px solid white;
  color: white;
  text-align: right;
  background-color: #030303;
}
@media only screen and (max-width: 53.125em) {
  .ch2-settings-options table th,
  .ch2-settings-options table td {
    min-width: 0px;
  }
}
.ch2-settings-options table th:first-child,
.ch2-settings-options table td:first-child {
  background-color: #030303;
  text-align: left;
}
@media only screen and (max-width: 25em) {
  .ch2-settings-options table th:first-child,
  .ch2-settings-options table td:first-child {
    min-width: 1px;
  }
}
.ch2-settings-options table th:nth-child(2),
.ch2-settings-options table td:nth-child(2) {
  background-color: #030303;
}
.ch2-settings-options table th:nth-child(3),
.ch2-settings-options table td:nth-child(3) {
  background-color: #030303;
}

.ch2-settings-tabs ul:not([class]) li {
  border-left: 0px;
}

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
  }
  html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    transition-duration: 0s;
    transition-timing-function: ease;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: none;
    transition-duration: 0.4s;
  }
  html:not(.no-js) [data-aos='fade-up'] {
    transform: translate3d(0, 60px, 0);
  }
  html:not(.no-js) [data-aos='fade-up--small'] {
    transform: translate3d(0, 30px, 0);
  }
  html:not(.no-js) [data-aos='fade-right'] {
    transform: translate3d(-60px, 0, 0);
  }
  html:not(.no-js) [data-aos='fade-left'] {
    transform: translate3d(60px, 0, 0);
  }
}
[data-aos-delay].aos-animate, [data-aos-delay].aos-animate:before, [data-aos-delay].aos-animate:after {
  transition-delay: 0.2s;
}

[data-aos-delay="2x"].aos-animate, [data-aos-delay="2x"].aos-animate:before, [data-aos-delay="2x"].aos-animate:after {
  transition-delay: 0.5s;
}

[data-aos-stagger].aos-animate:nth-child(1) {
  transition-delay: 0s;
}

[data-aos-stagger].aos-animate:nth-child(2) {
  transition-delay: 0.2s;
}

[data-aos-stagger].aos-animate:nth-child(3) {
  transition-delay: 0.4s;
}

[data-aos-stagger].aos-animate:nth-child(4) {
  transition-delay: 0.6s;
}

[data-aos-stagger].aos-animate:nth-child(5) {
  transition-delay: 0.8s;
}

[data-aos-stagger].aos-animate:nth-child(6) {
  transition-delay: 1s;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

/**
 * Show a grab cursor when using a draggable slider.
 * On mouse down (:active) the cursor will appear to grab the content.
 */
.slick-list.draggable .slick-slide {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.slick-list.draggable .slick-slide:active {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * Page-level constraining and wrapping elements.
 */
.container {
  display: block;
  width: 100%;
  max-width: calc(1280px + var(--container-padding) * 2);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
}

.text-container {
  display: block;
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Create ratio-bound content blocks, to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * @see http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * 1. Default is a 1:1 ratio (i.e. a perfect square).
 */
.ratio {
  position: relative;
  display: block;
}
.ratio:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
  /* [1] */
}

.ratio__content,
.ratio > iframe,
.ratio > embed,
.ratio > object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/**
 * Generate a series of ratio classes to be used like so:
 *
 *   <div class="ratio ratio--16:9">
 *
 */
.ratio--4\:3:before {
  padding-bottom: 75%;
}

.ratio--16\:9:before {
  padding-bottom: 56.25%;
}

/**
 * Stack object
 * Blocks of full-width flow content should have margin between them.
 * Defaults to 1em, can be increased by updating --stack-space variable
 * 
 * @see https://every-layout.dev/layouts/stack/
 */
.stack > * {
  margin-top: 0;
  margin-bottom: 0;
}
.stack > * + * {
  margin-top: var(--stack-space, 1em);
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pt-1 {
  padding-top: clamp(1.5em, 1.5vw, 1.5em);
}

.pb-1 {
  padding-bottom: clamp(1.5em, 1.5vw, 1.5em);
}

.mt-1 {
  margin-top: clamp(1.5em, 1.5vw, 1.5em);
}

.mb-1 {
  margin-bottom: clamp(1.5em, 1.5vw, 1.5em);
}

.pt-2 {
  padding-top: clamp(3em, 3vw, 3em);
}

.pb-2 {
  padding-bottom: clamp(3em, 3vw, 3em);
}

.mt-2 {
  margin-top: clamp(3em, 3vw, 3em);
}

.mb-2 {
  margin-bottom: clamp(3em, 3vw, 3em);
}

.pt-3 {
  padding-top: clamp(3em, 4.5vw, 4.5em);
}

.pb-3 {
  padding-bottom: clamp(3em, 4.5vw, 4.5em);
}

.mt-3 {
  margin-top: clamp(3em, 4.5vw, 4.5em);
}

.mb-3 {
  margin-bottom: clamp(3em, 4.5vw, 4.5em);
}

.pt-4 {
  padding-top: clamp(3em, 6vw, 6em);
}

.pb-4 {
  padding-bottom: clamp(3em, 6vw, 6em);
}

.mt-4 {
  margin-top: clamp(3em, 6vw, 6em);
}

.mb-4 {
  margin-bottom: clamp(3em, 6vw, 6em);
}

.pt-5 {
  padding-top: clamp(3em, 7.5vw, 7.5em);
}

.pb-5 {
  padding-bottom: clamp(3em, 7.5vw, 7.5em);
}

.mt-5 {
  margin-top: clamp(3em, 7.5vw, 7.5em);
}

.mb-5 {
  margin-bottom: clamp(3em, 7.5vw, 7.5em);
}

.pb-section {
  padding-bottom: clamp(100px, 17.5vw, 225px);
}
@media only screen and (max-width: 59.375em) {
  .pb-section {
    padding-bottom: 3em;
  }
}

.agent {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: 200;
}
@media only screen and (max-width: 47.9375em) {
  .agent {
    font-size: 1.1em;
  }
}
.agent a[href^="mailto:"] {
  text-decoration: none;
}
.agent a[href^="mailto:"]:focus, .agent a[href^="mailto:"]:hover {
  text-decoration: underline;
}

.agent__logo {
  flex: 1 0 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 47.9375em) {
  .agent__logo img {
    display: block;
    max-width: 190px;
    max-height: 120px;
    object-fit: contain;
  }
}

.agent__name {
  --flow-space: 1.5em;
  color: #c77f00;
  font-weight: 700;
  text-transform: uppercase;
}
.agent__name ~ * {
  --flow-space: 0.5em;
}

.agent-logo-only {
  width: 280px;
  height: auto;
}

.bg-pattern-right {
  position: relative;
  z-index: 0;
}
.bg-pattern-right:before, .bg-pattern-right:after {
  content: "";
  position: absolute;
  top: 0;
  right: 4vw;
  z-index: 0;
  width: 84px;
  background-size: contain;
}
@media only screen and (max-width: 87.5em) {
  .bg-pattern-right:before, .bg-pattern-right:after {
    right: 2vw;
  }
}
@media only screen and (max-width: 68.75em) {
  .page-building .bg-pattern-right:before, .page-space .bg-pattern-right:before, .page-privacy-policy .bg-pattern-right:before, .page-building .bg-pattern-right:after, .page-space .bg-pattern-right:after, .page-privacy-policy .bg-pattern-right:after {
    display: none;
  }
}
@media only screen and (max-width: 90.625em) {
  .page-location .bg-pattern-right:before, .page-location .bg-pattern-right:after {
    display: none;
  }
}
@media only screen and (max-width: 37.5em) {
  .page-404 .bg-pattern-right:before, .page-404 .bg-pattern-right:after {
    display: none;
  }
}
.bg-pattern-right:before {
  height: 54px;
  background-image: url("/static/pattern-body-top.svg");
}
.bg-pattern-right:after {
  top: 54px;
  bottom: 0;
  background-image: url("/static/pattern-body-repeat.svg");
  background-repeat: repeat-y;
}
.bg-pattern-right > * {
  position: relative;
  z-index: 1;
}

.page-home .bg-pattern-right:before, .page-home .bg-pattern-right:after {
  left: 100%;
  right: auto;
  transform: translateX(-50%);
}
@media only screen and (max-width: 100em) {
  .page-home .bg-pattern-right:before, .page-home .bg-pattern-right:after {
    left: auto;
    right: 5%;
    transform: none;
  }
}
@media only screen and (max-width: 59.375em) {
  .page-home .bg-pattern-right:before, .page-home .bg-pattern-right:after {
    display: none;
  }
}

.border-left {
  padding-left: 2.5em;
  border-left: 4px solid #c77f00;
}
@media only screen and (max-width: 75em) {
  .border-left {
    padding-left: 1.5em;
  }
}

.button {
  display: inline-block;
  padding: 0.5em 1em;
  border: 4px solid #c77f00;
  color: inherit;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.15s ease;
}
.button[aria-pressed="true"], .button:focus, .button:hover {
  background-color: #c77f00;
  color: white;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 30px;
}
@media only screen and (max-width: 35em) {
  .button-group .button {
    width: 100%;
  }
}

.contacts__title {
  font-size: 1.2em;
}
.contacts__title svg {
  display: block;
  width: auto;
  height: 17px;
  margin: 1em auto 0;
}

.contacts__agents {
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 47.9375em) {
  .contacts__agents {
    flex-direction: column;
  }
  .contacts__agents > * + * {
    margin-top: 3em;
  }
}

.content-columns {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: flex-start;
  gap: 8.3333333333%;
}
@media only screen and (max-width: 68.75em) {
  .content-columns {
    gap: 3em;
  }
}
@media only screen and (max-width: 56.25em) {
  .content-columns {
    flex-direction: column;
    gap: 3em;
  }
}
.content-columns > * {
  flex: 1 0 41.6666666667%;
  max-width: 41.6666666667%;
}
@media only screen and (max-width: 68.75em) {
  .content-columns > * {
    flex-basis: 50%;
    max-width: calc(50% - 1.5em);
  }
}
@media only screen and (max-width: 56.25em) {
  .content-columns > * {
    flex-basis: 100%;
    max-width: 100%;
  }
  .content-columns > * img:only-child {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 68.8125em) {
  .page-location .content-columns > :first-child {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.gallery-mt {
  margin-top: 30px;
}

.gallery__left {
  align-self: flex-start;
}
@media only screen and (max-width: 63.9375em) {
  .gallery__left {
    align-self: center;
  }
}
.gallery__left.offset-1 {
  margin-left: 8.3333333333%;
}
@media only screen and (max-width: 68.75em) {
  .gallery__left.offset-1 {
    margin-left: 0;
  }
}
.gallery__left.offset-2 {
  margin-left: 16.6666666667%;
}
@media only screen and (max-width: 81.25em) {
  .gallery__left.offset-2 {
    margin-left: 0;
  }
}

.gallery__right {
  align-self: flex-end;
}
@media only screen and (max-width: 63.9375em) {
  .gallery__right {
    align-self: center;
  }
}
.gallery__right.offset-1 {
  margin-right: 8.3333333333%;
}
@media only screen and (max-width: 68.75em) {
  .gallery__right.offset-1 {
    margin-right: 0;
  }
}
.gallery__right.offset-2 {
  margin-right: 16.6666666667%;
}
@media only screen and (max-width: 81.25em) {
  .gallery__right.offset-2 {
    margin-right: 0;
  }
}

.hero {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.hero__image,
.hero__video {
  display: block;
  width: 100%;
  height: 45vw;
  object-fit: cover;
}

.hero__disclosure {
  position: absolute;
  left: 0;
  bottom: 2em;
  z-index: 1;
  width: 100%;
  opacity: 0;
  transform: translateY(40px);
  transition: 0.5s cubic-bezier(0.02, 0.68, 0.39, 0.96) 0.1s;
}
.no-js .hero__disclosure, .hero.video-ended .hero__disclosure {
  opacity: 1;
  transform: translateY(0);
}
@media only screen and (max-width: 43.75em) {
  .hero__disclosure {
    display: none;
  }
}
.hero__disclosure a {
  color: white;
  text-decoration: none;
}
.hero__disclosure span {
  font-size: clamp(18px, calc(1rem + 2.2vw), 55px);
  text-transform: uppercase;
  font-weight: 700;
}
.hero__disclosure svg {
  display: block;
  width: auto;
  height: 17px;
  margin: 0.5em auto 0;
}

.image-carousel:not(.slick-initialized) *:not(:first-child) {
  display: none;
}
.image-carousel .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.image-carousel .slick-dots li + li {
  margin-left: 15px;
}
.image-carousel .slick-dots button {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #030303;
  font-size: 0;
  transition: 0.1s ease;
}
@media only screen and (max-width: 47.9375em) {
  .image-carousel .slick-dots button {
    width: 15px;
    height: 15px;
  }
}
.image-carousel .slick-dots .slick-active button,
.image-carousel .slick-dots button:hover {
  background-color: #c77f00;
}

.map {
  position: relative;
  z-index: 0;
}

.map__embed {
  display: block;
  width: 100%;
  height: 45vw;
  min-height: 400px;
  background-color: #e6e6e6;
}
.map__embed img {
  object-fit: initial;
}

.map__marker {
  display: none !important;
}

.map__preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100%;
  max-width: 650px;
  padding: 0 10px;
  margin-top: 0;
  color: inherit;
  text-align: center;
  text-decoration: none;
}

@media only screen and (min-width: 64em) {
  .map__preloader-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 63.9375em) {
  .map__preloader-wrap {
    text-align: center;
  }
}

.map__preloader-title {
  font-weight: 700;
  white-space: nowrap;
}
.map__preloader-title + p {
  margin-top: 0.5em;
}

.site-footer {
  background-color: #030303;
  color: white;
}
.site-footer * {
  outline-color: #c77f00;
}

.site-footer__copyright {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  font-size: 1rem;
  font-weight: 200;
}
@media only screen and (max-width: 47.9375em) {
  .site-footer__copyright {
    align-items: center;
    flex-direction: column;
    gap: 0px;
  }
}
.site-footer__copyright > * {
  padding-bottom: 2em;
}
@media only screen and (max-width: 47.9375em) {
  .site-footer__copyright > * {
    padding-bottom: 0.5em;
  }
}
.site-footer__copyright > *, .site-footer__copyright:after {
  flex: 1 0 33.33%;
  max-width: calc(33.33% - 10px);
}
@media only screen and (max-width: 47.9375em) {
  .site-footer__copyright > *, .site-footer__copyright:after {
    max-width: 100%;
  }
}
.site-footer__copyright:after {
  content: "";
  order: 2;
  display: block;
  width: 210px;
  height: 156px;
  background-image: url("/static/footer-pattern.svg");
  background-size: contain;
}
@media only screen and (max-width: 59.375em) {
  .site-footer__copyright:after {
    width: 150px;
    height: 111px;
  }
}
@media only screen and (max-width: 47.9375em) {
  .site-footer__copyright:after {
    flex-basis: auto;
    order: 3;
    margin-top: 2.5em;
  }
}

.site-footer__links {
  order: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.site-footer__links > * + *:before {
  content: " | ";
  display: inline-block;
  margin: 0 10px;
}
.site-footer__links a {
  text-underline-offset: 0.2em;
  text-decoration-color: rgba(255, 255, 255, 0.75);
}

.site-header {
  color: var(--header-color);
}

.site-logo {
  display: block;
  width: 550px;
  max-width: 90%;
  height: auto;
  margin: 1.5rem auto;
  color: inherit;
}
.site-logo svg {
  display: block;
}
@media only screen and (max-width: 34.375em) {
  .site-logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.site-nav {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 10px 0;
  background-color: #030303;
  color: white;
  text-align: center;
}
@media only screen and (max-width: 47.9375em) {
  .site-nav {
    padding: 5px 0;
  }
}

.site-nav__list {
  display: inline-flex;
  align-items: center;
  gap: 3rem;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
@media only screen and (max-width: 41.25em) {
  .site-nav__list {
    gap: 1rem;
  }
}
@media only screen and (max-width: 35em) {
  .site-nav__list {
    flex-wrap: wrap;
    row-gap: 0.25rem;
    font-size: 1rem;
  }
  .site-nav__list > * {
    flex: 1 0 50%;
    max-width: calc(50% - 0.5rem);
  }
}
.site-nav__list a {
  display: inline-block;
  padding: 5px 10px;
  border: 2px solid transparent;
  text-decoration: none;
  transition: 0.1s ease;
}
.site-nav__list a[aria-current="page"], .site-nav__list a:focus, .site-nav__list a:hover {
  border-color: #c77f00;
}

.svg-replace {
  position: relative;
  z-index: 0;
  color: transparent;
}
.svg-replace:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.svg-replace.-Change:after {
  background-image: url("/static/change.svg");
}

.wired-badge {
  width: auto;
  height: 180px;
}
@media only screen and (min-width: 32.5625em) and (max-width: 56.25em) {
  .wired-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}
@media only screen and (max-width: 37.5em) {
  .wired-badge {
    height: 140px;
  }
}

@media only screen and (min-width: 32.5625em) {
  .show\@520 {
    display: none !important;
  }
}

@media only screen and (max-width: 32.5em) {
  .hide\@520 {
    display: none !important;
  }
}

/**
 * Mixin to accessibly hide elements.
 * https://allyjs.io/tutorials/hiding-elements.html#2017-edition-of-visuallyhidden
 */
.sr-only:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/**
 * Alignment utilities
 */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.light {
  font-weight: 200 !important;
}

.color-gold {
  color: #c77f00 !important;
}


